@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,400;1,300&family=Roboto:wght@100;300;400;500&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
  font-family: "Open Sans", sans-serif;
  font-family: "Roboto", sans-serif;
}

.d-flex {
  display: flex;
}

.d-flex-align {
  display: flex;
  align-items: center;
}

.d-flex-align-justify {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* MAIN PAGE */
.main {
  width: 100%;
  height: 100vh;
}

/* HEADER */
.header {
  width: 100%;
  height: 60px;
  background-color: #0a1929;
  color: #dde0e3;
  padding: 0 20px;
}

.header .header-left-part {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 150px;
}

.header .header-left-part .logo span {
  color: orangered;
}

.header ul li {
  list-style-type: none;
  margin: 10px;
}

.header ul li .user-profile img {
  width: 35px;
  height: 35px;
  margin-right: 15px;
}

.header ul li .icon-badge {
  position: relative;
}

.header ul li .icon-badge span {
  position: absolute;
  top: -5px;
  background-color: #e2560a;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  font-size: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header ul li .menu-icon {
  font-size: 15px;
}

.header .sidebar-open-icon {
  display: none;
}

.header .sidebar-reduce-icon {
  cursor: pointer;
}

/* BODY */
.body {
  width: 100%;
  height: calc(100vh - 60px);
  background-color: #faf5f6;
  display: flex;
}

/* SIDEBAR */
.sidebar {
  border-top: 1px solid #ffffff66;
  width: 250px;
  background-color: #0a1929;
  height: 100%;
  overflow-y: auto;
  color: #dde0e3;
}

.sidebar li {
  padding: 10px;
  margin: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.sidebar li span {
  margin-left: 10px;
  font-size: 14px;
}

.sidebar li:hover {
  background-color: #e2560a;
  border-radius: 7px;
}

.selected_item {
  background-color: #e2560a;
  border-radius: 7px;
}

.sidebar ul li.selected {
  background-color: #e2560a;
  border-radius: 7px;
}

/* Dashboard body */

.dashboard-body {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.dashboard-header {
  border-radius: 10px;
  background-color: #f5f6f7;
  padding: 15px;
  margin: 20px 15px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.dashboard-header h1 {
  margin: 10px 0;
}

.reduceSidebar {
  border-top: 1px solid #ffffff66;
  width: 75px;
  background-color: #0a1929;
  height: 100%;
  overflow-y: auto;
  color: #dde0e3;
}

.reduceSidebar li span {
  display: none;
}

.reduceSidebar li {
  padding: 10px;
  margin: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.reduceSidebar li:hover {
  background-color: #e2560a;
  border-radius: 7px;
}

.reduceSidebar ul li.selected {
  background-color: #e2560a;
  border-radius: 7px;
}

/* BOTTOM NAVIGATION */

.bottom-nav {
  height: 55px;
  width: 100%;
  background-color: #0a1929;
  position: fixed;
  bottom: 0;
  z-index: 100;
  display: none;
}

.bottom-nav ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #dde0e3;
}

.bottom-nav ul li {
  list-style-type: none;
  margin: 10px;
}

.bottom-nav ul li .user-profile img {
  width: 35px;
  height: 35px;
  margin-right: 15px;
}

.bottom-nav ul li .icon-badge {
  position: relative;
}

.bottom-nav ul li .icon-badge span {
  position: absolute;
  top: -5px;
  background-color: #e2560a;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  font-size: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-nav ul li .menu-icon {
  font-size: 20x;
}

.bottom-nav .sidebar-open-icon {
  display: none;
}
/* drawer */
.ant-drawer-body {
  background-color: #0a1929 !important;
  overflow-y: auto !important;
  color: #dde0e3 !important;
}

.ant-drawer-body li {
  padding: 10px !important;
  margin: 10px !important;
  display: flex !important;
  align-items: center !important;
  cursor: pointer !important;
}

.ant-drawer-body li span {
  margin-left: 10px !important;
  font-size: 14px !important;
}

.ant-drawer-body li:hover {
  background-color: #e2560a !important;
  border-radius: 7px !important;
}

.selected_item {
  background-color: #e2560a !important;
  border-radius: 7px !important;
}

.ant-drawer-body ul li.selected {
  background-color: #e2560a !important;
  border-radius: 7px !important;
}

/* body */
.dashboard-body .statistics {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  margin: 15px;
}

.dashboard-body .chartside {
  display: flex;
  margin: 15px;
}

.dashboard-body .chartside .chart-left {
  width: 40%;
  height: 300px;
  background-color: white;
  border-radius: 10px;
  margin: 5px;
}

.dashboard-body .chartside .chart-right {
  width: 60%;
  background-color: white;
  border-radius: 10px;
  margin: 5px;
}

.dashboard-body .dashboard-table {
  margin: 15px;
}

.dashboard-body .calendar {
  margin: 15px;
}

.dashboard-body .tasklist {
  margin: 15px;
}

@media screen and (max-width: 1000px) {
  .dashboard-body .statistics {
    grid-template-columns: repeat(2, 1fr);
  }
  .dashboard-body .chartside {
    flex-direction: column;
  }

  .dashboard-body .chartside .chart-left {
    width: 100%;
  }

  .dashboard-body .chartside .chart-right {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .dashboard-body .statistics {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 379px) {
  .header ul {
    display: none;
  }

  .sidebar {
    display: none;
  }

  .reduceSidebar {
    display: block;
  }

  .header .sidebar-open-icon {
    display: block;
    cursor: pointer;
  }

  .bottom-nav {
    display: block;
  }

  .header .sidebar-reduce-icon {
    display: none;
  }
}
